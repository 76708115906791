<template>
  <v-data-table
      dense
      :headers="headers"
      :items="routines"
      item-key="id"
      class="elevation-1"
      :loading="loading"
      loading-text="Loading... Please wait"
  >
    <template #body>
      <draggable v-model="sortedRoutines" @start="drag=true" @end="drag=false" tag="tbody">
        <tr v-for="(item, index) in sortedRoutines" :key="index">
          <td>{{ item.title }}</td>
          <td>
            <v-img :aspect-ratio="16/9" width="200" :src="item.photo" alt="No photo available"></v-img>
          </td>
          <td>
            <div v-html="item.body"/>
          </td>
          <td>
            <v-icon
                color="blue"
                small
                class="mr-2"
                @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
                color="red"
                small
                @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </td>
        </tr>
      </draggable>
    </template>
    <template v-slot:top>
      <v-toolbar
          flat
      >
        <v-toolbar-title>All Routine</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
            v-model="dialog"
            max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                icon
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="editedItem.title"
                        label="Enter routine title"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                  >
                    <tiptap-vuetify
                        v-model="editedItem.body"
                        :extensions="extensions"
                        placeholder="Enter routine details"
                    >
                    </tiptap-vuetify>
                  </v-col>
                  <v-col
                      cols="12"
                  >
                    <v-file-input
                        clearable
                        label="Choose a photo"
                        v-model="photo"
                    ></v-file-input>
                  </v-col>
                  <v-col
                      cols="12"
                  >
                    <v-file-input
                        clearable
                        label="Choose a pdf"
                        v-model="pdf"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
  </v-data-table>
</template>

<script>
import axios from "axios";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from 'tiptap-vuetify'
import draggable from "vuedraggable";

export default {
  name: "ManageRoutines",
  components: {TiptapVuetify, draggable},
  data: () => ({
    drag: false,
    dialog: false,
    dialogDelete: false,
    loading: false,
    photo: null,
    pdf: null,
    footerProps: {
      itemsPerPageOptions: [20, 50, 100, 500]
    },
    page: 1,
    pageCount: 0,
    totalRoutines: 0,
    options: {},
    headers: [
      {
        text: 'Routine title',
        align: 'start',
        value: 'title',
      },
      {
        text: 'Photo',
        value: 'photo',
      },
      {
        text: 'Content',
        value: 'body',
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false
      },
    ],
    routines: [],
    sortedRoutines: [],
    editedIndex: -1,
    editedItem: {
      title: '',
      body: '',
    },
    defaultItem: {
      title: '',
      body: '',
    },
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      Bold,
      Link,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    form() {
      let formData = new FormData()
      if (this.editedIndex > -1) {
        formData.append('_method', 'PUT')
      }
      formData.append('title', this.editedItem.title)
      formData.append('body', this.editedItem.body)
      formData.append('photo', this.photo)
      formData.append('pdf', this.pdf)
      return formData
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    routines() {
      this.sortedRoutines = this.routines
    },
    sortedRoutines(newVal, oldVal) {
      if (oldVal.length) {
        this.sortItems()
      }
    }
  },

  created() {
    this.initialize()
  },

  methods: {
    sortItems() {
      const url = 'admin/routines'
      let data = {
        routines: this.sortedRoutines,
        type: 'sort'
      }
      axios.post(url, data)
    },

    initialize() {
      this.loading = true
      const url = 'admin/routines'
      axios.get(url).then((response) => {
        this.routines = response.data
        this.sortedRoutines = response.data
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    editItem(item) {
      this.editedIndex = this.routines.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.routines.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      const url = 'admin/routines/' + this.editedItem.id
      let editIndex = this.editedIndex
      axios.delete(url).then(() => {
        this.routines.splice(editIndex, 1)
      }).catch((error) => {
        console.log(error)
      })
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.photo = null
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        const url = 'admin/routines/' + this.editedItem.id
        const index = this.editedIndex
        axios.post(url, this.form).then((response) => {
          Object.assign(this.routines[index], response.data)
        }).catch((error) => {
          console.log(error)
        })
      } else {
        const url = 'admin/routines'
        axios.post(url, this.form).then((response) => {
          this.routines.push(response.data)
        }).catch((error) => {
          console.log(error)
        })
      }
      this.close()
    },
  },
}
</script>

<style scoped>

</style>